.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-table-cell--faq-divider::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  bottom: 0;
  width: 1px;
  height: 22px;
  background-color: #d9d9d9;
  transform: translateY(-50%);
}

.faqTable .ant-table-content {
  overflow: visible !important;
}

